<template>
  <base-modal class="create-tutorial-modal" :isOpened="isOpen" @close="closeModal">
    <template v-slot:header> {{ isUpdate ? 'ATUALIZAR': 'ADICIONAR' }} TUTORIAL</template>
    <template v-slot:content>
      <form @keypress.enter="handleSubmitTutorial" @submit.prevent="handleSubmitTutorial">
        <base-input-text
          v-model="tutorial.link"
          type="link"
          label="Link"
          placeholder="https://youtube.com"
          block
          :error="errors.link"
        />
        <div class="buttons">
          <base-button @click="closeModal" danger>Cancelar</base-button>
          <base-button 
            type="submit" 
            :disabled="!formIsValid()"
            :success="!isUpdate"
          >
            {{ isUpdate ? 'Atualizar' : 'Adicionar' }}
          </base-button>
        </div>
      </form>
    </template>
  </base-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'Modal',
  props: {
    isOpen: { type: Boolean, default: false },
  },
  data(){
    return {
      isUpdate: false,
      errors: {
        link: ''
      }
    }
  },
  computed: {
    ...mapGetters('tutorials', {
      tutorial: 'getTutorial',
    }),
  },
  methods: {
    ...mapActions('tutorials', {
      handleSetTutorial: 'handleSetTutorial',
    }),
    closeModal() {
      this.handleSetTutorial({})
      this.$emit('close')
    },
    formIsValid(){
      if(!this.tutorial.link) {
        this.errors.link = "Este campo precisa ser preenchido"
        return false
      }

      const isLink = this.tutorial.link.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      
      if(isLink === null) {
        this.errors.link = "Este campo precisa ser um link"
        return false
      }

      this.errors.link = ''

      return true
    },
    async handleSubmitTutorial() {
      try {
        if (!this.formIsValid()) return;
        this.tutorial.link = `https://www.youtube.com/oembed?url=${this.tutorial.link}&format=json`
        const { data, status } = await axios.get(this.tutorial.link.replace('v%', 'v='))
        if(status === 200 && data && data.title) {
          this.tutorial.title = data.title
        }
        if(this.isUpdate) this.$emit('update')
        else this.$emit('create')
      } catch (e) {
      }
    },
  },
  watch: {
    tutorial(){
      if(this.tutorial._id) this.isUpdate = true
      else this.isUpdate = false
    }
  }
}
</script>
<style lang="scss" scoped>

.create-tutorial-modal {
  /deep/ header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /deep/ main {
    margin: 16px;
    form {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .buttons{
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
}
</style>
